import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import testimonialsStyles from "./testimonials.module.scss"
import SectionHeader from "../section-header/section-header"

function Testimonials(props) {
  const { hideHeader } = props
  return (
    <div className={testimonialsStyles.container}>
      {!hideHeader && (
        <SectionHeader
          header="Testimonials"
          subHeader="See What they said about us"
        />
      )}
      <div className={testimonialsStyles.slider_container}>
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={6100}
        >
          <div className={testimonialsStyles.slider_content}>
            <div className={testimonialsStyles.my_carousel}>
              <h3>Katherine</h3>
              <h4></h4>
              <p>
                All your parties should have the proper event planner. Amber
                Olvera is the perfect person for the job. She scrutinizes the
                details that make or break the atmosphere and enjoyment for the
                celebration. Without an event planner, people just show up. With
                an event well organized by Amber, you can relax, and enjoy the
                milestones and traditions of life. You can also rest assured
                that she pays close attention to the budget. Amber researches
                for the most cost effective venues, cuisine options and all of
                the other necessary decorations, etc to make your event a truly
                memorable celebration. Once you allow her to take charge of your
                events, you will never want to go it alone again.
              </p>
            </div>
          </div>
          <div className={testimonialsStyles.slider_content}>
            <div className={testimonialsStyles.my_carousel}>
              <h3>Amy</h3>
              <h4></h4>
              <p>
                Amber helped to coordinate a surprise birthday party for me,
                that went so great, we used her again for our baby shower! It is
                so important to have a planner that you trust; it truly makes
                the event stress-free! She worked well with other vendors and
                made everything run smoothly. Joyous events is now our go-to
                event planner and we highly recommend them for any party needs.
              </p>
            </div>
          </div>
          <div className={testimonialsStyles.slider_content}>
            <div className={testimonialsStyles.my_carousel}>
              <h3>David</h3>
              <h4></h4>
              <p>
                I have never made a big deal out of my birthday, but we decided
                to celebrate my big birthday this year. Joyous events planned
                everything from theme, coordinated catering and decor vendors,
                sent out invitations on our behalf and even oversaw a slideshow
                and speakers. This was the best birthday I’ve had! Not only was
                the event great, but Amber is so great to work with as well. We
                will definitely use them again for future events.
              </p>
            </div>
          </div>
          <div className={testimonialsStyles.slider_content}>
            <div className={testimonialsStyles.my_carousel}>
              <h3>Christina</h3>
              <h4></h4>
              <p>
              Being in a new area, I was unsure how to go about planning a baby shower. Left in Amber’s hands, she designed an e-vite, which made it easy for me to forward to guests, she found an amazing location and designed the best shower that fit me perfectly. My guests and I alike were very impressed. I highly recommend Joyous Events and will definitely use them again in the future. 

              </p>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  )
}

export default Testimonials
